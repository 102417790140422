import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../context/LanguageContext';
import ContactForm from './ContactForm';
import SocialLinks from './SocialLinks';
import Map from './Map';

const ContactSection = () => {
  const { language } = useLanguage();

  return (
    <section id="contact" className="relative min-h-screen bg-black py-24">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-900/20 via-black to-black" />
      
      <div className="relative container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-6xl text-white mb-16 uppercase relative"
        >
          {language === 'de' ? 'Kontakt' : 'Contact'}
        </motion.h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <ContactForm />
          <div className="space-y-16">
            <SocialLinks />
            <Map />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;