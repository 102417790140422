import React from 'react';
import { Film, Edit, Plane, Globe } from 'lucide-react';
import ServiceCard from '../Services/ServiceCard';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const ServicesSection = () => {
  const { language } = useLanguage();
  const t = translations.services[language];

  const services = [
    {
      icon: <Film size={40} />,
      title: t.filmProduction,
      description: t.filmDesc,
      link: "/services/film-production",
      bgImage: "https://images.unsplash.com/photo-1485846234645-a62644f84728?auto=format&fit=crop&q=80&w=2000",
      alt: "Film Production Equipment"
    },
    {
      icon: <Edit size={40} />,
      title: t.postProduction,
      description: t.postDesc,
      link: "/services/post-production",
      bgImage: "https://i.ibb.co/RgDynj2/post-production.jpg",
      alt: "Post Production Studio"
    },
    {
      icon: <Plane size={40} />,
      title: t.dronePhotography,
      description: t.droneDesc,
      link: "/services/drone-service",
      bgImage: "https://i.ibb.co/xzFSt0p/drone-services.jpg",
      alt: "Drone Photography"
    },
    {
      icon: <Globe size={40} />,
      title: t.webDesign,
      description: t.webDesc,
      link: "/services/web-design",
      bgImage: "https://images.unsplash.com/photo-1547658719-da2b51169166?auto=format&fit=crop&q=80&w=2000",
      alt: "Web Design Workspace"
    }
  ];

  return (
    <section id="services" className="min-h-screen bg-[#1a1a2e] py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-transparent pointer-events-none" />
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-6xl text-white mb-16 uppercase relative">
          <span className="relative z-10">{t.title}</span>
          <div className="absolute -left-4 top-1/2 -translate-y-1/2 w-2 h-16 bg-purple-500 blur-sm" />
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <ServiceCard 
              key={index} 
              {...service} 
              exploreText={t.exploreService}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;