import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import Navigation from '../components/Navigation/Navigation';
import PostProductionSection from '../components/Sections/PostProductionSection';
import WorkSection from '../components/Sections/WorkSection';
import ServicesSection from '../components/Sections/ServicesSection';
import DroneSection from '../components/Sections/DroneSection';
import TestimonialsSection from '../components/Sections/TestimonialsSection';
import AboutSection from '../components/Sections/About/AboutSection';
import ContactSection from '../components/Sections/Contact/ContactSection';
import Footer from '../components/Footer/Footer';
import Preloader from '../components/Preloader/Preloader';
import GridBackground from '../components/GridBackground';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-black min-h-screen">
      {isLoading && <Preloader />}
      <main className="relative min-h-screen bg-black">
        <GridBackground />
        <div className="bg-gradient-blur" />
        <div className="bg-gradient-overlay" />
        <Navigation />
        <Hero />
        <PostProductionSection />
        <WorkSection />
        <DroneSection />
        <TestimonialsSection />
        <ServicesSection />
        <AboutSection />
        <ContactSection />
        <Footer />
      </main>
    </div>
  );
};

export default HomePage;