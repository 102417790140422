import React from 'react';
import { motion } from 'framer-motion';

interface BiographyTitleProps {
  title: string;
}

const BiographyTitle = ({ title }: BiographyTitleProps) => {
  return (
    <motion.h3
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="text-3xl text-purple-400 font-bold"
    >
      {title}
    </motion.h3>
  );
};

export default BiographyTitle;