import React from 'react';
import { useLanguage } from '../../../context/LanguageContext';

interface VideoPlayerProps {
  videoId: string;
  title: string;
  onClose?: () => void;
}

const VideoPlayer = ({ videoId, title }: VideoPlayerProps) => {
  const { language } = useLanguage();

  return (
    <div className="relative aspect-video w-full bg-black rounded-lg overflow-hidden">
      {/* Video Title Overlay */}
      <div className="absolute top-0 left-0 right-0 z-10 bg-gradient-to-b from-black/80 to-transparent p-4">
        <h3 className="text-white text-lg font-medium">
          {language === 'de' ? 'Projekt-Highlights: ' : 'Project Highlights: '}
          <span className="text-purple-400">{title}</span>
        </h3>
      </div>

      {/* Vimeo Player */}
      <iframe
        src={`https://player.vimeo.com/video/${videoId}?h=ef9b5d6f6a&title=0&byline=0&portrait=0&controls=1&dnt=1`}
        className="absolute inset-0 w-full h-full"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        loading="lazy"
        title={title}
      />
    </div>
  );
};

export default VideoPlayer;