import React, { useRef } from 'react';
import { Youtube, Twitch, Globe } from 'lucide-react';
import { useInView } from '../../../hooks/useInView';
import AnimatedCounter from '../../UI/AnimatedCounter';

interface TestimonialCardProps {
  name: string;
  platform: string;
  followers: string;
  image: string;
  socials: {
    youtube?: string;
    twitch?: string;
    website?: string;
  };
  delay: number;
}

const TestimonialCard = ({ name, platform, followers, image, socials, delay }: TestimonialCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef);
  
  const subscriberCount = parseInt(followers.replace(/[^0-9]/g, ''));
  const suffix = followers.includes('Abonnenten') ? ' Abonnenten' : ' Subscribers';

  return (
    <div
      ref={cardRef}
      className="relative group"
      style={{
        transform: isInView ? 'translateY(0)' : 'translateY(50px)',
        opacity: isInView ? 1 : 0,
        transition: `all 0.8s cubic-bezier(0.4, 0, 0.2, 1) ${delay}ms`
      }}
    >
      {/* Glow Effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
      
      <div className="relative flex flex-col bg-black/80 backdrop-blur-xl p-8 rounded-lg border border-white/10 hover:border-purple-500/30 transition-all duration-500 transform hover:-translate-y-2 hover:shadow-2xl">
        <div className="flex items-center space-x-6 mb-6">
          <div className="relative w-20 h-20 transform group-hover:scale-110 transition-transform duration-300">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-75"></div>
            <img
              src={image}
              alt={name}
              className="relative w-full h-full object-cover rounded-full ring-2 ring-purple-500/20 group-hover:ring-purple-500/50 transition-all duration-300"
            />
          </div>
          <div>
            <h3 className="text-2xl text-white font-bold group-hover:text-purple-400 transition-colors duration-300">{name}</h3>
            <p className="text-purple-400 group-hover:text-purple-300 transition-colors duration-300">{platform}</p>
            <p className="text-white/60 text-sm">
              <AnimatedCounter end={subscriberCount} suffix={suffix} />
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-4 mt-6">
          {socials.youtube && (
            <a
              href={socials.youtube}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-red-500 transition-all duration-300 transform hover:scale-110"
            >
              <Youtube size={24} />
            </a>
          )}
          {socials.twitch && (
            <a
              href={socials.twitch}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-purple-500 transition-all duration-300 transform hover:scale-110"
            >
              <Twitch size={24} />
            </a>
          )}
          {socials.website && (
            <a
              href={socials.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white/60 hover:text-blue-400 transition-all duration-300 transform hover:scale-110"
            >
              <Globe size={24} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;