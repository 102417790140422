import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ImpressumPage from './pages/ImpressumPage';
import DatenschutzPage from './pages/DatenschutzPage';
import FilmProductionPage from './components/Services/FilmProduction/FilmProductionPage';
import PostProductionPage from './components/Services/PostProduction/PostProductionPage';
import DroneServicePage from './components/Services/DroneService/DroneServicePage';
import WebDesignPage from './components/Services/WebDesign/WebDesignPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="/datenschutz" element={<DatenschutzPage />} />
        <Route path="/services/film-production" element={<FilmProductionPage />} />
        <Route path="/services/post-production" element={<PostProductionPage />} />
        <Route path="/services/drone-service" element={<DroneServicePage />} />
        <Route path="/services/web-design" element={<WebDesignPage />} />
      </Routes>
    </Router>
  );
}

export default App;