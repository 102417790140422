import React from 'react';
import { motion } from 'framer-motion';
import { FormspreeState } from '@formspree/react';

interface FormStatusProps {
  state: FormspreeState<any>;
  successMessage: string;
  errorMessage: string;
}

const FormStatus = ({ state, successMessage, errorMessage }: FormStatusProps) => {
  if (!state.succeeded && !state.errors) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`text-center ${state.succeeded ? 'text-green-400' : 'text-red-400'}`}
    >
      {state.succeeded ? successMessage : errorMessage}
    </motion.div>
  );
};

export default FormStatus;