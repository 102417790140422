import { useLanguage } from '../context/LanguageContext';

interface Testimonial {
  id: number;
  name: string;
  platform: string;
  followers: string;
  image: string;
  socials: {
    youtube?: string;
    twitch?: string;
    website?: string;
  };
}

export const useTestimonials = (): Testimonial[] => {
  const { language } = useLanguage();

  return [
    {
      id: 1,
      name: 'knirpz',
      platform: 'Twitch & YouTube',
      followers: language === 'de' ? '23.308 Abonnenten' : '23,308 Subscribers',
      image: 'https://i.ibb.co/WV7F4pT/knirpz.jpg',
      socials: {
        twitch: 'https://www.twitch.tv/knirpz',
        youtube: 'https://www.youtube.com/c/knirpz'
      }
    },
    {
      id: 2,
      name: 'YuraYami',
      platform: 'Twitch & YouTube',
      followers: language === 'de' ? '1.610 Abonnenten' : '1,610 Subscribers',
      image: 'https://i.ibb.co/0ZB8VHp/yurayami.jpg',
      socials: {
        twitch: 'https://www.twitch.tv/yurayami',
        youtube: 'https://www.youtube.com/@YuraYami'
      }
    },
    {
      id: 3,
      name: 'Heinz-Brandt-Schule',
      platform: 'Educational Institution',
      followers: language === 'de' ? '5.000 Abonnenten' : '5,000 Subscribers',
      image: 'https://i.ibb.co/q1bhcpH/heinz-brandt.jpg',
      socials: {
        website: 'https://www.heinz-brandt-schule.de'
      }
    }
  ];
};