import React from 'react';

const GridBackground = () => {
  return (
    <div className="fixed inset-0 -z-30 pointer-events-none">
      <div className="absolute inset-0 bg-black">
        {/* Main Grid Pattern */}
        <div className="minimal-grid" />
        
        {/* Subtle Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black/50" />
      </div>
      
      {/* Ambient Light Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-radial from-purple-900/5 to-transparent opacity-30" />
        <div className="absolute bottom-0 right-0 w-full h-full bg-gradient-radial from-blue-900/5 to-transparent opacity-30" />
      </div>
    </div>
  );
};

export default GridBackground;