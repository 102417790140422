import React, { useState } from 'react';
import { Camera, Film, HardDrive, Calendar, User, Play } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import VideoPlayer from './FeaturedProject/VideoPlayer';

interface ProjectProps {
  project: {
    id: number;
    title: string;
    description: string;
    thumbnail: string;
    category: string;
    videoId: string;
    specs: {
      icon: string;
      label: string;
      value: string;
    }[];
  };
}

const FeaturedProject = ({ project }: ProjectProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { language } = useLanguage();

  const getIcon = (iconName: string) => {
    const icons = {
      camera: <Camera className="w-5 h-5" />,
      hardDrive: <HardDrive className="w-5 h-5" />,
      calendar: <Calendar className="w-5 h-5" />,
      user: <User className="w-5 h-5" />,
      film: <Film className="w-5 h-5" />
    };
    return icons[iconName as keyof typeof icons];
  };

  return (
    <div className="max-w-6xl mx-auto px-4">
      <div className="relative aspect-video rounded-xl overflow-hidden group">
        {isPlaying ? (
          <VideoPlayer 
            videoId={project.videoId} 
            title={project.title}
            onClose={() => setIsPlaying(false)}
          />
        ) : (
          <>
            <img
              src={project.thumbnail}
              alt={project.title}
              className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
              <div className="absolute inset-0 flex items-center justify-center">
                <button
                  onClick={() => setIsPlaying(true)}
                  className="bg-purple-600/80 p-8 rounded-full text-white transform scale-100 group-hover:scale-110 transition-transform duration-500 hover:bg-purple-600"
                  aria-label={language === 'de' ? "Video abspielen" : "Play video"}
                >
                  <Play size={48} className="ml-2" />
                </button>
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <span className="text-purple-400 text-sm uppercase tracking-wider">
                  {project.category}
                </span>
                <h3 className="text-white text-3xl font-bold mt-2">
                  {project.title}
                </h3>
                <p className="text-white/80 mt-2 text-lg mb-6">
                  {project.description}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                  {project.specs.map((spec, index) => (
                    <div 
                      key={index}
                      className="flex items-center space-x-3 text-white/80"
                    >
                      <div className="text-purple-400">
                        {getIcon(spec.icon)}
                      </div>
                      <div>
                        <div className="text-xs text-white/60">{spec.label}</div>
                        <div className="text-sm">{spec.value}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturedProject;