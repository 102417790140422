import React from 'react';
import { useLanguage } from '../../../context/LanguageContext';
import TestimonialCard from './TestimonialCard';
import { useTestimonials } from '../../../hooks/useTestimonials';

const TestimonialsList = () => {
  const { language } = useLanguage();
  const testimonials = useTestimonials();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
      {testimonials.map((testimonial, index) => (
        <TestimonialCard
          key={testimonial.id}
          {...testimonial}
          delay={index * 200}
        />
      ))}
    </div>
  );
};

export default TestimonialsList;