import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import './styles/swiper.css';
import './styles/select.css';
import './styles/mobile.css';
import { LanguageProvider } from './context/LanguageContext';
import { enableSmoothScroll } from './utils/scroll';

const container = document.getElementById('root');

// Enable smooth scrolling globally
enableSmoothScroll();

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </StrictMode>
  );
}