import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface AnimatedSkillIconProps {
  icon: LucideIcon;
  index: number;
}

const AnimatedSkillIcon = ({ icon: Icon, index }: AnimatedSkillIconProps) => {
  return (
    <motion.div
      initial={{ scale: 0, rotate: -180 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: index * 0.1
      }}
      className="text-purple-400"
    >
      <Icon className="w-6 h-6" />
    </motion.div>
  );
};

export default AnimatedSkillIcon;