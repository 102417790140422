import React from 'react';

interface PostProductionFeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const PostProductionFeature = ({ icon, title, description }: PostProductionFeatureProps) => {
  return (
    <div className="group bg-black/50 backdrop-blur-sm p-6 rounded-lg border border-white/10 hover:border-purple-500/30 transition-all duration-300 transform hover:-translate-y-1">
      <div className="text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
      <h3 className="text-xl text-white mb-2">{title}</h3>
      <p className="text-white/80">{description}</p>
    </div>
  );
};

export default PostProductionFeature;