import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import Footer from '../components/Footer/Footer';

const ImpressumPage = () => {
  const { language } = useLanguage();

  return (
    <div className="min-h-screen bg-black">
      <div className="container mx-auto px-6 py-24">
        <Link 
          to="/"
          className="inline-flex items-center text-white/60 hover:text-white mb-12 transition-colors"
        >
          <ArrowLeft className="mr-2" size={20} />
          {language === 'de' ? 'Zurück zur Startseite' : 'Back to Homepage'}
        </Link>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="prose prose-invert max-w-none"
        >
          <h1 className="text-4xl mb-8">Impressum</h1>

          <h2 className="text-2xl mt-8 mb-4">Angaben gemäß § 5 TMG</h2>
          <p>
            Lars Janetzke<br />
            Filmproduktion<br />
            Berlin<br />
            Deutschland
          </p>

          <h2 className="text-2xl mt-8 mb-4">Kontakt</h2>
          <p>
            E-Mail: Info@larsjanetzke.de
          </p>

          <h2 className="text-2xl mt-8 mb-4">Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
            [Ihre USt-IdNr]
          </p>

          <h2 className="text-2xl mt-8 mb-4">Streitschlichtung</h2>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: 
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer" className="text-purple-400 hover:text-purple-300 ml-1">
              https://ec.europa.eu/consumers/odr/
            </a>
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <p className="mt-4">
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer 
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>

          <h2 className="text-2xl mt-8 mb-4">Haftung für Inhalte</h2>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den 
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht 
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen 
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </p>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default ImpressumPage;