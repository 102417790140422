import React from 'react';
import { useLanguage } from '../../../../context/LanguageContext';

interface TitleOverlayProps {
  title: string;
}

const TitleOverlay = ({ title }: TitleOverlayProps) => {
  const { language } = useLanguage();

  return (
    <div className="absolute top-0 left-0 right-0 z-10 bg-gradient-to-b from-black/80 to-transparent p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <h3 className="text-white text-lg font-medium">
        {language === 'de' ? 'Drohnenaufnahmen: ' : 'Drone Footage: '}
        <span className="text-purple-400">{title}</span>
      </h3>
    </div>
  );
};

export default TitleOverlay;