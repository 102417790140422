import React from 'react';
import Typewriter from 'typewriter-effect';

const TypewriterText = () => {
  return (
    <div className="inline-block text-6xl md:text-8xl uppercase text-white">
      <Typewriter
        options={{
          loop: true,
          autoStart: true,
          strings: ['EMOTIONEN', 'GESCHICHTEN', 'GÄNSEHAUT'],
          delay: 75,
          deleteSpeed: 50,
          pauseFor: 2500,
        }}
      />
    </div>
  );
};

export default TypewriterText;