import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import FeaturedProjects from '../Work/FeaturedProjects';
import SectionTitle from '../UI/SectionTitle';

const WorkSection = () => {
  const { language } = useLanguage();

  return (
    <section id="work" className="min-h-screen bg-[#1a1a2e] py-24 relative">
      <div className="absolute inset-0 bg-gradient-to-t from-purple-900/20 to-transparent pointer-events-none" />
      <div className="container mx-auto relative z-10">
        {/* SEO-optimized heading */}
        <h2 className="sr-only">
          {language === 'de' 
            ? 'Projekt-Highlights - Ausgewählte Filmproduktionen und Dokumentationen'
            : 'Project Highlights - Selected Film Productions and Documentaries'
          }
        </h2>
        <SectionTitle>
          {language === 'de' ? 'Projekt-Highlights' : 'Project Highlights'}
        </SectionTitle>
        <FeaturedProjects />
      </div>
    </section>
  );
};

export default WorkSection;