import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from '../../../hooks/useInView';

interface StatsCounterProps {
  icon: React.ReactNode;
  value: number;
  label: string;
  suffix: string;
  index: number;
}

const StatsCounter = ({ icon, value, label, suffix, index }: StatsCounterProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  const variants = {
    hidden: { 
      opacity: 0, 
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        duration: 0.6,
        delay: index * 0.2,
        ease: "easeOut"
      }
    }
  };

  const numberVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        delay: index * 0.2 + 0.3,
        ease: "easeOut"
      }
    }
  };

  const iconVariants = {
    hidden: { opacity: 0, rotate: -180, scale: 0 },
    visible: {
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        duration: 0.6,
        delay: index * 0.2,
        ease: "easeOut",
        bounce: 0.5
      }
    }
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      className="relative group"
    >
      {/* Glow Effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-200" />
      
      <div className="relative flex flex-col items-center p-6 bg-black/50 backdrop-blur-sm rounded-lg border border-white/10 hover:border-purple-500/30 transition-all duration-300">
        {/* Icon */}
        <motion.div
          variants={iconVariants}
          className="text-purple-400 mb-4"
        >
          {icon}
        </motion.div>

        {/* Value */}
        <motion.div
          variants={numberVariants}
          className="text-4xl font-bold text-white mb-2 flex items-center"
        >
          {isInView && (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: index * 0.2 + 0.6 }}
            >
              {value}{suffix}
            </motion.span>
          )}
        </motion.div>

        {/* Label */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
          transition={{ duration: 0.6, delay: index * 0.2 + 0.9 }}
          className="text-white/60 text-center"
        >
          {label}
        </motion.div>

        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-16 h-16 bg-purple-500/5 rounded-bl-full transform rotate-12 group-hover:rotate-45 transition-transform duration-300" />
        <div className="absolute bottom-0 left-0 w-12 h-12 bg-blue-500/5 rounded-tr-full transform -rotate-12 group-hover:rotate-45 transition-transform duration-300" />
      </div>
    </motion.div>
  );
};

export default StatsCounter;