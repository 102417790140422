import React from 'react';
import { motion } from 'framer-motion';
import { Plane, Camera, Mountain, Video } from 'lucide-react';
import ServiceHero from '../common/ServiceHero';
import ServiceFeature from '../common/ServiceFeature';
import { useLanguage } from '../../../context/LanguageContext';

const DroneServicePage = () => {
  const { language } = useLanguage();

  const features = [
    {
      icon: <Camera className="w-8 h-8" />,
      title: language === 'de' ? '4K HDR Aufnahmen' : '4K HDR Footage',
      description: language === 'de'
        ? 'Hochauflösende Luftaufnahmen in bester Qualität'
        : 'High-resolution aerial footage in best quality'
    },
    {
      icon: <Mountain className="w-8 h-8" />,
      title: language === 'de' ? 'Vielseitige Einsätze' : 'Versatile Operations',
      description: language === 'de'
        ? 'Von Immobilien bis Naturaufnahmen'
        : 'From real estate to nature shots'
    },
    {
      icon: <Video className="w-8 h-8" />,
      title: language === 'de' ? 'Cineastische Shots' : 'Cinematic Shots',
      description: language === 'de'
        ? 'Dynamische Flugmanöver für beeindruckende Perspektiven'
        : 'Dynamic flight maneuvers for impressive perspectives'
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      <ServiceHero
        title={language === 'de' ? 'Drohnenaufnahmen' : 'Drone Photography'}
        subtitle={language === 'de'
          ? 'Atemberaubende Luftaufnahmen und Fotografie'
          : 'Breathtaking aerial cinematography and photography'
        }
        backgroundImage="https://images.unsplash.com/photo-1506947411487-a56738267384?auto=format&fit=crop&q=80&w=2000"
        icon={<Plane className="w-12 h-12" />}
      />

      <motion.div 
        className="container mx-auto px-6 py-24 grid grid-cols-1 md:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {features.map((feature, index) => (
          <ServiceFeature
            key={index}
            {...feature}
            index={index}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default DroneServicePage;