import React from 'react';
import DroneFrame from '../Drone/DroneFrame';
import DroneTechSpecs from '../Drone/DroneTechSpecs';
import DroneServices from '../Drone/DroneServices';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';
import SectionTitle from '../UI/SectionTitle';

const DroneSection = () => {
  const { language } = useLanguage();
  const t = translations.drone[language];

  return (
    <section className="relative min-h-screen bg-black overflow-hidden">
      {/* Mountain Background */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-fixed opacity-80"
        style={{
          backgroundImage: `url('https://i.ibb.co/SK1ZRvS/2024-08-10-22-1.jpg')`
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/90 via-black/70 to-black/90" />
      </div>

      {/* Purple Glow Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-purple-900/30 mix-blend-overlay" />
        <div className="absolute inset-0 bg-gradient-radial from-purple-900/30 to-transparent opacity-50" />
      </div>

      {/* Content Container */}
      <div className="relative container mx-auto px-6 py-24">
        <SectionTitle>{t.title}</SectionTitle>

        {/* Main Content */}
        <div className="space-y-24">
          <DroneFrame />
          <DroneTechSpecs />
          <DroneServices />
        </div>

        {/* Enhanced Atmospheric Effects */}
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-radial from-purple-500/20 to-transparent opacity-40 blur-3xl" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-radial from-purple-500/20 to-transparent opacity-40 blur-3xl" />
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl animate-pulse" />
      </div>
    </section>
  );
};

export default DroneSection;