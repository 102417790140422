import React from 'react';
import { Code, Video, Clapperboard, Sparkles } from 'lucide-react';
import { useLanguage } from '../../../context/LanguageContext';
import AnimatedSkillCard from '../../UI/AnimatedSkillCard';

const Skills = () => {
  const { language } = useLanguage();

  const skills = [
    {
      icon: Code,
      name: 'DaVinci Resolve Studio',
      level: 95,
      description: 'Color Grading & Finishing'
    },
    {
      icon: Video,
      name: 'Adobe After Effects',
      level: 90,
      description: 'Motion Design & VFX'
    },
    {
      icon: Clapperboard,
      name: 'Adobe Premiere Pro',
      level: 85,
      description: 'Editing & Assembly'
    },
    {
      icon: Sparkles,
      name: 'Cinema 4D',
      level: 80,
      description: '3D Animation'
    }
  ];

  return (
    <div className="mt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {skills.map((skill, index) => (
        <AnimatedSkillCard
          key={index}
          {...skill}
          index={index}
        />
      ))}
    </div>
  );
};

export default Skills;