import React from 'react';
import { Wand2, Palette, Film, Music } from 'lucide-react';
import { useLanguage } from '../../../context/LanguageContext';
import PostProductionFeature from './Feature';

const Features = () => {
  const { language } = useLanguage();

  const features = [
    {
      icon: <Wand2 className="w-8 h-8" />,
      title: language === 'de' ? 'Professionelle Farbkorrektur' : 'Professional Color Grading',
      description: language === 'de' 
        ? 'Hochwertige Farbkorrektur mit DaVinci Resolve Studio für cineastische Looks'
        : 'High-end color grading with DaVinci Resolve Studio for cinematic looks'
    },
    {
      icon: <Palette className="w-8 h-8" />,
      title: language === 'de' ? 'Visuelle Effekte' : 'Visual Effects',
      description: language === 'de'
        ? 'Kreative VFX und Motion Design für beeindruckende Resultate'
        : 'Creative VFX and motion design for stunning results'
    },
    {
      icon: <Film className="w-8 h-8" />,
      title: language === 'de' ? 'Präziser Schnitt' : 'Precision Editing',
      description: language === 'de'
        ? 'Dynamischer Schnitt mit Fokus auf Storytelling und Emotion'
        : 'Dynamic editing focused on storytelling and emotion'
    },
    {
      icon: <Music className="w-8 h-8" />,
      title: language === 'de' ? 'Sound Design' : 'Sound Design',
      description: language === 'de'
        ? 'Professionelles Audio-Mixing und atmosphärische Soundscapes'
        : 'Professional audio mixing and atmospheric soundscapes'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
      {features.map((feature, index) => (
        <PostProductionFeature key={index} {...feature} />
      ))}
    </div>
  );
};

export default Features;