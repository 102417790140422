import React from 'react';
import { motion } from 'framer-motion';
import { Camera, Award, Globe } from 'lucide-react';
import { useLanguage } from '../../../context/LanguageContext';
import ParallaxBackground from './ParallaxBackground';
import StatsCounter from './StatsCounter';
import BiographySection from './BiographySection';

const AboutSection = () => {
  const { language } = useLanguage();

  const stats = [
    {
      icon: <Camera className="w-6 h-6" />,
      value: 60,
      label: language === 'de' ? 'Projekte' : 'Projects',
      suffix: '+'
    },
    {
      icon: <Award className="w-6 h-6" />,
      value: 3,
      label: language === 'de' ? 'Jahre Erfahrung' : 'Years Experience',
      suffix: ''
    },
    {
      icon: <Globe className="w-6 h-6" />,
      value: 5,
      label: language === 'de' ? 'Länder' : 'Countries',
      suffix: '+'
    }
  ];

  return (
    <section id="about" className="relative min-h-screen overflow-hidden">
      <ParallaxBackground />
      
      <div className="relative container mx-auto px-6 py-24">
        <motion.h2
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-6xl text-white mb-16 uppercase relative"
        >
          {language === 'de' ? 'Über Mich' : 'About Me'}
        </motion.h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <BiographySection />
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {stats.map((stat, index) => (
              <StatsCounter key={index} {...stat} index={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;