import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import Footer from '../components/Footer/Footer';

const DatenschutzPage = () => {
  const { language } = useLanguage();

  return (
    <div className="min-h-screen bg-black">
      <div className="container mx-auto px-6 py-24">
        <Link 
          to="/"
          className="inline-flex items-center text-white/60 hover:text-white mb-12 transition-colors"
        >
          <ArrowLeft className="mr-2" size={20} />
          {language === 'de' ? 'Zurück zur Startseite' : 'Back to Homepage'}
        </Link>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="prose prose-invert max-w-none"
        >
          <h1 className="text-4xl mb-8">Datenschutzerklärung</h1>

          <h2 className="text-2xl mt-8 mb-4">1. Datenschutz auf einen Blick</h2>
          <h3 className="text-xl mt-6 mb-3">Allgemeine Hinweise</h3>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten 
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie 
            persönlich identifiziert werden können.
          </p>

          <h2 className="text-2xl mt-8 mb-4">2. Kontaktformular und Formspree</h2>
          <p>
            Wenn Sie das Kontaktformular auf unserer Website nutzen, verwenden wir den Dienst Formspree zur 
            Verarbeitung Ihrer Anfrage. Die von Ihnen im Formular eingegebenen Daten (Name, E-Mail-Adresse, 
            Betreff und Nachricht) werden an Formspree übermittelt und dort gespeichert.
          </p>
          <p>
            Formspree ist ein Dienst der Formspree, Inc. Weitere Informationen zum Umgang mit Nutzerdaten 
            finden Sie in der Datenschutzerklärung von Formspree: 
            <a 
              href="https://formspree.io/legal/privacy-policy/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-purple-400 hover:text-purple-300 ml-1"
            >
              https://formspree.io/legal/privacy-policy/
            </a>
          </p>

          <h2 className="text-2xl mt-8 mb-4">3. Ihre Rechte</h2>
          <p>
            Sie haben jederzeit das Recht:
          </p>
          <ul>
            <li>Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten (Art. 15 DSGVO)</li>
            <li>Die Berichtigung unrichtiger personenbezogener Daten zu verlangen (Art. 16 DSGVO)</li>
            <li>Die Löschung Ihrer gespeicherten personenbezogenen Daten zu verlangen (Art. 17 DSGVO)</li>
            <li>Die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen (Art. 18 DSGVO)</li>
          </ul>

          <h2 className="text-2xl mt-8 mb-4">4. Kontakt</h2>
          <p>
            Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, 
            Berichtigung, Sperrung oder Löschung von Daten wenden Sie sich bitte an:
          </p>
          <p>
            Lars Janetzke<br />
            E-Mail: Info@larsjanetzke.de
          </p>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default DatenschutzPage;