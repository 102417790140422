import React from 'react';
import { useLanguage } from '../../../context/LanguageContext';

const Preview = () => {
  const { language } = useLanguage();

  return (
    <div className="relative aspect-video rounded-lg overflow-hidden group">
      <img 
        src="https://i.ibb.co/187Cf1r/IMG-20241130-WA0006.jpg"
        alt="DaVinci Resolve Studio Interface"
        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent" />
      <div className="absolute bottom-0 left-0 right-0 p-8">
        <h3 className="text-2xl text-white mb-2">DaVinci Resolve Studio</h3>
        <p className="text-white/80">
          {language === 'de' 
            ? 'Professionelle Farbkorrektur und High-End Finishing'
            : 'Professional Color Grading and High-End Finishing'}
        </p>
      </div>
    </div>
  );
};

export default Preview;