import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

const Footer = () => {
  const { language } = useLanguage();
  const year = new Date().getFullYear();

  return (
    <footer className="bg-black/80 backdrop-blur-sm border-t border-white/10">
      <div className="container mx-auto px-6 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-white/60 text-sm">
            © {year} Lars Janetzke. {language === 'de' ? 'Alle Rechte vorbehalten.' : 'All rights reserved.'}
          </div>
          <div className="flex items-center space-x-6">
            <Link 
              to="/impressum" 
              className="text-white/60 hover:text-white text-sm transition-colors"
            >
              Impressum
            </Link>
            <Link 
              to="/datenschutz" 
              className="text-white/60 hover:text-white text-sm transition-colors"
            >
              {language === 'de' ? 'Datenschutz' : 'Privacy Policy'}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;