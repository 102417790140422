import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import NavLink from './NavLink';
import LanguageSwitcher from './LanguageSwitcher';
import MobileMenu from './MobileMenu';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { language } = useLanguage();
  const t = translations.nav[language];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { href: '#work', text: t.work },
    { href: '#services', text: t.services },
    { href: '#about', text: t.about },
    { href: '#contact', text: t.contact }
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 flex justify-center px-4 py-6">
      <div 
        className={`
          w-[90%] max-w-7xl rounded-lg transition-all duration-500
          ${isScrolled 
            ? 'bg-black/80 backdrop-blur-lg shadow-lg shadow-purple-500/10' 
            : 'bg-black/40 backdrop-blur-sm'
          }
        `}
      >
        <div className="flex items-center justify-between h-16 px-6">
          {/* Logo */}
          <div className="relative group">
            <div className="absolute -inset-2 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-200"></div>
            <a 
              href="#" 
              className="relative text-2xl font-bold text-white hover:text-purple-400 transition-colors duration-300"
            >
              Lars Janetzke
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-center flex-1 space-x-8">
            {navItems.map((item, index) => (
              <div
                key={item.href}
                className="fade-in"
                style={{ animationDelay: `${index * 100}ms` }}
              >
                <NavLink href={item.href}>{item.text}</NavLink>
              </div>
            ))}
          </div>

          {/* Language Switcher */}
          <div className="hidden md:block fade-in" style={{ animationDelay: '400ms' }}>
            <LanguageSwitcher />
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden relative group p-2"
          >
            <div className="absolute -inset-2 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-300"></div>
            <div className="relative text-white">
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </div>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMobileMenuOpen} 
        onClose={() => setIsMobileMenuOpen(false)}
        items={navItems}
      />
    </nav>
  );
};

export default Navigation;