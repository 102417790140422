import React from 'react';
import TypewriterText from './TypewriterText';
import VideoBackground from './VideoBackground';

const Hero = () => {
  return (
    <section className="relative min-h-screen">
      <VideoBackground />
      <div className="relative flex justify-center items-center min-h-screen text-center">
        {/* SEO-optimized heading structure */}
        <div className="flex flex-col md:flex-row items-center md:space-x-6 fade-in px-4">
          <h1 className="sr-only">Lars Janetzke - Professionelle Filmproduktion in Berlin</h1>
          <span className="text-6xl md:text-8xl uppercase slide-in-right stagger-1 text-white font-bold">
            ICH KREIERE
          </span>
          <TypewriterText />
        </div>
      </div>
    </section>
  );
};

export default Hero;