import React from 'react';
import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

interface FormSelectProps {
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  options: { value: string; label: string }[];
  required?: boolean;
  delay?: number;
}

const FormSelect = ({ 
  id, 
  value, 
  onChange, 
  label, 
  options,
  required = true,
  delay = 0 
}: FormSelectProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay }}
      className="relative group"
    >
      <select
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        className="w-full appearance-none bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white 
                 focus:border-purple-500 transition-all duration-300 cursor-pointer
                 hover:bg-white/10 focus:ring-2 focus:ring-purple-500/20 focus:outline-none"
        required={required}
      >
        <option value="" disabled className="bg-gray-900 text-white">
          {label}
        </option>
        {options.map((option) => (
          <option 
            key={option.value} 
            value={option.value}
            className="bg-gray-900 text-white py-2"
          >
            {option.label}
          </option>
        ))}
      </select>
      
      {/* Custom Dropdown Arrow */}
      <div className="absolute right-4 top-1/2 -translate-y-1/2 text-white/60 pointer-events-none
                    transition-transform duration-300 group-focus-within:text-purple-400">
        <ChevronDown 
          size={20}
          className="transform group-focus-within:rotate-180 transition-transform duration-300"
        />
      </div>

      {/* Floating Label */}
      <label
        htmlFor={id}
        className={`absolute left-4 -top-2 text-xs bg-black px-2 
                   ${value ? 'text-purple-400' : 'text-white/60'}
                   transition-all duration-200`}
      >
        {label}
      </label>

      {/* Focus Ring */}
      <div className="absolute inset-0 rounded-lg bg-purple-500/5 opacity-0 
                    transition-opacity duration-300 pointer-events-none
                    group-focus-within:opacity-100" />
    </motion.div>
  );
};

export default FormSelect;