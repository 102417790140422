import React from 'react';
import { motion } from 'framer-motion';

interface ServiceFeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  index: number;
}

const ServiceFeature = ({ icon, title, description, index }: ServiceFeatureProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
      className="group bg-white/5 backdrop-blur-sm p-8 rounded-lg border border-white/10 hover:border-purple-500/30 transition-all duration-300"
    >
      <motion.div
        className="text-purple-400 mb-6 transform group-hover:scale-110 transition-transform duration-300"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 200, damping: 10, delay: 0.2 + index * 0.1 }}
      >
        {icon}
      </motion.div>
      <h3 className="text-2xl text-white mb-4">{title}</h3>
      <p className="text-white/70">{description}</p>
    </motion.div>
  );
};

export default ServiceFeature;