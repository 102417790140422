import React from 'react';
import { motion } from 'framer-motion';

interface FormSubmitButtonProps {
  isSubmitting: boolean;
  label: string;
  children?: React.ReactNode;
}

const FormSubmitButton = ({ isSubmitting, label, children }: FormSubmitButtonProps) => {
  return (
    <motion.button
      type="submit"
      disabled={isSubmitting}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: 0.5 }}
      className={`w-full bg-purple-500 hover:bg-purple-600 text-white py-3 px-6 rounded-lg flex items-center justify-center space-x-2 transition-colors ${
        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      <span>{isSubmitting ? '...' : label}</span>
      {children}
    </motion.button>
  );
};

export default FormSubmitButton;