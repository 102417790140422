import React, { useEffect, useState } from 'react';
import { Film } from 'lucide-react';

const Preloader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [frame, setFrame] = useState(1);

  useEffect(() => {
    // Animate frame counter
    const frameInterval = setInterval(() => {
      setFrame(prev => (prev % 24) + 1);
    }, 1000 / 24); // 24 fps

    // Hide preloader after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000);

    return () => {
      clearInterval(frameInterval);
      clearTimeout(timer);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
      <div className="relative flex flex-col items-center">
        {/* Film Icon */}
        <Film 
          className="w-12 h-12 text-white/20 animate-spin-slow mb-4" 
          style={{ animationDuration: '4s' }} 
        />
        
        {/* Frame Counter */}
        <div className="font-mono text-sm text-white/50">
          {frame.toString().padStart(2, '0')} / 24
        </div>

        {/* Film Strip Effect */}
        <div className="absolute -inset-16">
          <div className="absolute top-0 left-0 w-full h-4 bg-white/5">
            <div className="flex justify-between px-2">
              {[...Array(8)].map((_, i) => (
                <div key={i} className="w-2 h-4 bg-white/10" />
              ))}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full h-4 bg-white/5">
            <div className="flex justify-between px-2">
              {[...Array(8)].map((_, i) => (
                <div key={i} className="w-2 h-4 bg-white/10" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;