import React from 'react';
import { Play, Pause, Volume2, VolumeX, Maximize, Minimize } from 'lucide-react';

interface DronePlayerControlsProps {
  isPlaying: boolean;
  isMuted: boolean;
  isFullscreen: boolean;
  onPlayToggle: () => void;
  onMuteToggle: () => void;
  onFullscreenToggle: () => void;
}

const DronePlayerControls = ({
  isPlaying,
  isMuted,
  isFullscreen,
  onPlayToggle,
  onMuteToggle,
  onFullscreenToggle
}: DronePlayerControlsProps) => {
  return (
    <div className="flex items-center justify-between mt-2">
      <div className="flex items-center space-x-2 sm:space-x-4">
        <button
          onClick={onPlayToggle}
          className="text-white hover:text-purple-400 transition-colors p-2"
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? <Pause size={20} className="sm:w-6 sm:h-6" /> : <Play size={20} className="sm:w-6 sm:h-6" />}
        </button>
        
        <button
          onClick={onMuteToggle}
          className="text-white hover:text-purple-400 transition-colors p-2"
          aria-label={isMuted ? 'Unmute' : 'Mute'}
        >
          {isMuted ? <VolumeX size={20} className="sm:w-6 sm:h-6" /> : <Volume2 size={20} className="sm:w-6 sm:h-6" />}
        </button>
      </div>

      <div className="flex items-center">
        <button
          onClick={onFullscreenToggle}
          className="text-white hover:text-purple-400 transition-colors p-2"
          aria-label={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
        >
          {isFullscreen ? <Minimize size={20} className="sm:w-6 sm:h-6" /> : <Maximize size={20} className="sm:w-6 sm:h-6" />}
        </button>
      </div>
    </div>
  );
};

export default DronePlayerControls;