import React, { useRef } from 'react';
import { useInView } from '../../hooks/useInView';

interface DroneCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  delay: number;
}

const DroneCard = ({ icon, title, description, delay }: DroneCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef);

  return (
    <div
      ref={cardRef}
      className="relative group"
      style={{
        transform: isInView ? 'translateY(0)' : 'translateY(50px)',
        opacity: isInView ? 1 : 0,
        transition: `all 0.6s cubic-bezier(0.4, 0, 0.2, 1) ${delay}ms`
      }}
    >
      {/* Glow Effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-75 transition duration-500 group-hover:duration-200"></div>
      
      <div className="relative flex flex-col h-full bg-black/80 backdrop-blur-xl p-8 rounded-lg border border-white/10 hover:border-purple-500/30 transition-all duration-300 transform hover:-translate-y-1 hover:shadow-2xl">
        <div className="text-purple-400 mb-6 transform group-hover:scale-110 transition-transform duration-300">
          {icon}
        </div>
        
        <h3 className="text-2xl text-white font-bold mb-4 group-hover:text-purple-400 transition-colors duration-300">
          {title}
        </h3>
        
        <p className="text-white/70 group-hover:text-white/90 transition-colors duration-300">
          {description}
        </p>
        
        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-24 h-24 bg-purple-500/5 rounded-bl-full transform rotate-12 group-hover:rotate-45 transition-transform duration-300"></div>
        <div className="absolute bottom-0 left-0 w-16 h-16 bg-blue-500/5 rounded-tr-full transform -rotate-12 group-hover:rotate-45 transition-transform duration-300"></div>
      </div>
    </div>
  );
};

export default DroneCard;