import React from 'react';
import { Building2, Video, Camera, Mountain, Heart, Music } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import DroneCard from './DroneCard';

const DroneServices = () => {
  const { language } = useLanguage();

  const services = [
    {
      icon: <Building2 size={32} />,
      title: language === 'de' ? "Immobilienmarketing" : "Real Estate Marketing",
      description: language === 'de' 
        ? "Professionelle Luftaufnahmen für die optimale Präsentation Ihrer Immobilie"
        : "Professional aerial shots for optimal property presentation"
    },
    {
      icon: <Video size={32} />,
      title: language === 'de' ? "Eventdokumentation" : "Event Documentation",
      description: language === 'de'
        ? "Einzigartige Perspektiven für unvergessliche Momente"
        : "Unique perspectives for unforgettable moments"
    },
    {
      icon: <Camera size={32} />,
      title: language === 'de' ? "Imagefilme" : "Image Films",
      description: language === 'de'
        ? "Cineastische Luftaufnahmen für Ihren Unternehmensfilm"
        : "Cinematic aerial footage for your corporate film"
    },
    {
      icon: <Mountain size={32} />,
      title: language === 'de' ? "Landschaftsaufnahmen" : "Landscape Photography",
      description: language === 'de'
        ? "Atemberaubende Naturaufnahmen aus der Vogelperspektive"
        : "Breathtaking nature shots from a bird's eye view"
    },
    {
      icon: <Heart size={32} />,
      title: language === 'de' ? "Hochzeiten" : "Weddings",
      description: language === 'de'
        ? "Magische Momente aus einer neuen Perspektive festhalten"
        : "Capture magical moments from a new perspective"
    },
    {
      icon: <Music size={32} />,
      title: language === 'de' ? "Festivals" : "Festivals",
      description: language === 'de'
        ? "Spektakuläre Aufnahmen von Musikfestivals und Großveranstaltungen"
        : "Spectacular footage of music festivals and major events"
    }
  ];

  return (
    <div className="mt-16 max-w-6xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <DroneCard
            key={index}
            {...service}
            delay={index * 100} // Reduced delay for smoother animations
          />
        ))}
      </div>
    </div>
  );
};

export default DroneServices;