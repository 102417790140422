import React from 'react';
import { useYouTubePlayer } from './hooks/useYouTubePlayer';
import TitleOverlay from './components/TitleOverlay';
import ThumbnailView from './components/ThumbnailView';
import DronePlayerControls from './DronePlayerControls';
import DronePlayerProgress from './DronePlayerProgress';

interface DronePlayerProps {
  videoId: string;
  title: string;
  thumbnail?: string;
}

const DronePlayer = ({ videoId, title, thumbnail }: DronePlayerProps) => {
  const {
    isPlaying,
    isMuted,
    isFullscreen,
    progress,
    duration,
    playerRef,
    iframeRef,
    togglePlay,
    toggleMute,
    toggleFullscreen,
    getEmbedUrl
  } = useYouTubePlayer({ videoId });

  return (
    <div 
      ref={playerRef}
      className="relative aspect-video w-full bg-black rounded-lg overflow-hidden group touch-none"
      data-testid="drone-player"
    >
      <TitleOverlay title={title} />

      {!isPlaying && thumbnail ? (
        <ThumbnailView 
          thumbnail={thumbnail}
          title={title}
          onPlay={togglePlay}
        />
      ) : (
        <iframe
          ref={iframeRef}
          src={getEmbedUrl()}
          className="absolute inset-0 w-full h-full"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title}
        />
      )}

      <div className="absolute inset-0 flex flex-col justify-end opacity-100 sm:opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="bg-gradient-to-t from-black/90 via-black/50 to-transparent p-2 sm:p-4">
          <DronePlayerProgress progress={progress} duration={duration} />
          <DronePlayerControls
            isPlaying={isPlaying}
            isMuted={isMuted}
            isFullscreen={isFullscreen}
            onPlayToggle={togglePlay}
            onMuteToggle={toggleMute}
            onFullscreenToggle={toggleFullscreen}
          />
        </div>
      </div>
    </div>
  );
};

export default DronePlayer;