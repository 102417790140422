import React from 'react';

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle = ({ children }: SectionTitleProps) => {
  return (
    <h2 className="text-6xl text-white mb-16 uppercase relative px-6">
      <span className="relative z-10">{children}</span>
      <div className="absolute -left-4 top-1/2 -translate-y-1/2 w-2 h-16 bg-purple-500 blur-sm" />
    </h2>
  );
};

export default SectionTitle;