import React from 'react';
import { smoothScroll } from '../../utils/scroll';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  isMobile?: boolean;
  onClick?: () => void;
}

const NavLink = ({ href, children, isMobile = false, onClick }: NavLinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    smoothScroll(href);
    onClick?.();
  };

  return (
    <a 
      href={href} 
      onClick={handleClick}
      className={`
        relative group flex items-center justify-center
        ${isMobile 
          ? 'text-3xl text-white/80 hover:text-white transition-colors duration-300' 
          : 'text-lg text-white/80 hover:text-white transition-all duration-300'
        }
      `}
    >
      {/* Glow Effect */}
      <div className="absolute -inset-2 bg-gradient-to-r from-purple-600 to-blue-500 rounded-lg blur opacity-0 group-hover:opacity-25 transition duration-500" />
      
      {/* Link Text */}
      <span className="relative">
        {children}
        
        {/* Underline Effect */}
        <span className={`
          absolute -bottom-1 left-0 w-0 h-0.5 bg-purple-500
          transition-all duration-300 group-hover:w-full
          ${isMobile ? 'opacity-0' : 'opacity-100'}
        `} />
      </span>
    </a>
  );
};

export default NavLink;