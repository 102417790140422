import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import AnimatedSkillBar from './AnimatedSkillBar';
import AnimatedSkillIcon from './AnimatedSkillIcon';

interface AnimatedSkillCardProps {
  icon: LucideIcon;
  name: string;
  level: number;
  description: string;
  index: number;
}

const AnimatedSkillCard = ({ icon, name, level, description, index }: AnimatedSkillCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-black/50 backdrop-blur-sm p-6 rounded-lg border border-purple-500/20 transform hover:scale-105 transition-transform duration-300"
    >
      <div className="flex items-center space-x-4 mb-6">
        <AnimatedSkillIcon icon={icon} index={index} />
        <div className="flex-1 min-w-0">
          <motion.h4
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.15 }}
            className="text-white font-medium truncate"
          >
            {name}
          </motion.h4>
          <motion.p
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className="text-sm text-white/60 truncate"
          >
            {description}
          </motion.p>
        </div>
      </div>

      <AnimatedSkillBar progress={level} index={index} />

      <motion.div
        className="flex justify-between items-center mt-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: index * 0.25 }}
      >
        <span className="text-sm text-white/60">0%</span>
        <motion.span
          className="text-sm text-white/60"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: index * 0.3 + 1.5 }}
        >
          {level}%
        </motion.span>
      </motion.div>
    </motion.div>
  );
};

export default AnimatedSkillCard;