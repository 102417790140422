import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Smartphone, Zap, Layout } from 'lucide-react';
import ServiceHero from '../common/ServiceHero';
import ServiceFeature from '../common/ServiceFeature';
import { useLanguage } from '../../../context/LanguageContext';

const WebDesignPage = () => {
  const { language } = useLanguage();

  const features = [
    {
      icon: <Layout className="w-8 h-8" />,
      title: language === 'de' ? 'Modernes Design' : 'Modern Design',
      description: language === 'de'
        ? 'Zeitgemäße Gestaltung mit beeindruckender Optik'
        : 'Contemporary design with impressive visuals'
    },
    {
      icon: <Smartphone className="w-8 h-8" />,
      title: language === 'de' ? 'Responsive' : 'Responsive',
      description: language === 'de'
        ? 'Perfekte Darstellung auf allen Geräten'
        : 'Perfect display on all devices'
    },
    {
      icon: <Zap className="w-8 h-8" />,
      title: language === 'de' ? 'Performance' : 'Performance',
      description: language === 'de'
        ? 'Optimierte Ladezeiten und beste Nutzererfahrung'
        : 'Optimized loading times and best user experience'
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      <ServiceHero
        title={language === 'de' ? 'Webdesign' : 'Web Design'}
        subtitle={language === 'de'
          ? 'Moderne, responsive Websites mit beeindruckender Optik'
          : 'Modern, responsive websites with stunning visuals'
        }
        backgroundImage="https://images.unsplash.com/photo-1547658719-da2b51169166?auto=format&fit=crop&q=80&w=2000"
        icon={<Globe className="w-12 h-12" />}
      />

      <motion.div 
        className="container mx-auto px-6 py-24 grid grid-cols-1 md:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {features.map((feature, index) => (
          <ServiceFeature
            key={index}
            {...feature}
            index={index}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default WebDesignPage;