import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedSkillBarProps {
  progress: number;
  index: number;
}

const AnimatedSkillBar = ({ progress, index }: AnimatedSkillBarProps) => {
  return (
    <motion.div 
      className="relative h-2 bg-white/10 rounded-full overflow-hidden"
      initial={{ opacity: 0, scaleX: 0 }}
      animate={{ opacity: 1, scaleX: 1 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div 
        className="absolute top-0 left-0 h-full bg-purple-500 rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ 
          duration: 1.5,
          delay: index * 0.2,
          ease: "easeOut"
        }}
      >
        <motion.div
          className="absolute top-0 right-0 h-full w-2 bg-white/30"
          animate={{ 
            opacity: [0.3, 0.6, 0.3],
            x: [0, 10, 0]
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default AnimatedSkillBar;