import React from 'react';
import { motion } from 'framer-motion';
import { Youtube, Instagram, Mail } from 'lucide-react';

const SocialLinks = () => {
  const socials = [
    {
      icon: <Youtube size={24} />,
      label: 'YouTube',
      href: 'https://www.youtube.com/@Larsjanetzkee',
      color: 'hover:text-red-500'
    },
    {
      icon: <Instagram size={24} />,
      label: 'Instagram',
      href: 'https://www.instagram.com/lars.janetzke/',
      color: 'hover:text-pink-500'
    },
    {
      icon: <Mail size={24} />,
      label: 'Email',
      href: 'mailto:Info@larsjanetzke.de',
      color: 'hover:text-purple-400'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      {socials.map((social, index) => (
        <motion.a
          key={social.label}
          href={social.href}
          target="_blank"
          rel="noopener noreferrer"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: index * 0.1 }}
          className={`flex items-center space-x-3 p-4 bg-white/5 rounded-lg border border-white/10 ${social.color} transition-colors`}
        >
          {social.icon}
          <span className="text-white">{social.label}</span>
        </motion.a>
      ))}
    </div>
  );
};

export default SocialLinks;