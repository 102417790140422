import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, CheckCircle } from 'lucide-react';
import { useForm } from '@formspree/react';
import { useLanguage } from '../../../context/LanguageContext';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import FormSubmitButton from './FormSubmitButton';
import FormStatus from './FormStatus';

const ContactForm = () => {
  const { language } = useLanguage();
  const [state, handleSubmit] = useForm('mpwzewgv');
  const [subject, setSubject] = useState('');

  const labels = {
    name: language === 'de' ? 'Name' : 'Name',
    email: language === 'de' ? 'E-Mail' : 'Email',
    subject: language === 'de' ? 'Betreff' : 'Subject',
    message: language === 'de' ? 'Nachricht' : 'Message',
    submit: language === 'de' ? 'Nachricht senden' : 'Send Message',
    success: language === 'de' 
      ? 'Ich werde mich bei Ihnen melden.' 
      : 'I will get back to you.',
    error: language === 'de' 
      ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.' 
      : 'An error occurred. Please try again.'
  };

  const subjectOptions = [
    {
      value: 'film-production',
      label: language === 'de' ? 'Filmproduktion' : 'Film Production'
    },
    {
      value: 'post-production',
      label: language === 'de' ? 'Postproduktion' : 'Post Production'
    },
    {
      value: 'drone',
      label: language === 'de' ? 'Drohnenaufnahmen' : 'Drone Photography'
    },
    {
      value: 'web',
      label: language === 'de' ? 'Webdesign' : 'Web Design'
    },
    {
      value: 'other',
      label: language === 'de' ? 'Sonstiges' : 'Other'
    }
  ];

  if (state.succeeded) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-white/5 backdrop-blur-sm rounded-lg border border-green-500/20 p-8 text-center space-y-4"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2 }}
          className="flex justify-center"
        >
          <CheckCircle className="w-16 h-16 text-green-400" />
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-xl text-white"
        >
          {labels.success}
        </motion.p>
      </motion.div>
    );
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append('subject', subject);
    await handleSubmit(e);
  };

  return (
    <motion.form
      onSubmit={handleFormSubmit}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="space-y-6"
    >
      <FormInput
        id="name"
        type="text"
        name="name"
        label={labels.name}
        required
      />

      <FormInput
        id="email"
        type="email"
        name="email"
        label={labels.email}
        required
      />

      <FormSelect
        id="subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        label={labels.subject}
        options={subjectOptions}
        required
        delay={0.2}
      />

      <FormTextArea
        id="message"
        name="message"
        label={labels.message}
        required
      />

      <FormSubmitButton 
        isSubmitting={state.submitting} 
        label={labels.submit}
      >
        <Send size={18} className={state.submitting ? 'animate-pulse' : ''} />
      </FormSubmitButton>

      <FormStatus
        state={state}
        successMessage={labels.success}
        errorMessage={labels.error}
      />
    </motion.form>
  );
};

export default ContactForm;