import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

interface TechSpecProps {
  title: string;
  description: string;
}

const TechSpec = ({ title, description }: TechSpecProps) => (
  <div className="bg-black/50 backdrop-blur-sm p-6 rounded-lg border border-white/10 hover:border-purple-500/30 transition-colors duration-300">
    <h3 className="text-purple-400 text-lg font-bold mb-2">{title}</h3>
    <p className="text-white/80">{description}</p>
  </div>
);

const DroneTechSpecs = () => {
  const { language } = useLanguage();
  const t = translations.drone[language];

  const specs = [
    { title: 'Resolution', description: '4K/60fps HDR mit D-Log M' },
    { title: t.camera, description: 'DJI Mini 4K' },
    { title: t.storage, description: language === 'de' ? 'Jakobsweg Dokumentation' : 'Camino de Santiago Documentary' }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 max-w-4xl mx-auto">
      {specs.map((spec, index) => (
        <TechSpec key={index} {...spec} />
      ))}
    </div>
  );
};

export default DroneTechSpecs;