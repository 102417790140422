export const translations = {
  nav: {
    de: {
      work: 'Projekt-Highlights',
      services: 'Leistungen',
      about: 'Über mich',
      contact: 'Kontakt'
    },
    en: {
      work: 'Project Highlights',
      services: 'Services',
      about: 'About me',
      contact: 'Contact'
    }
  },
  services: {
    de: {
      title: 'Leistungen',
      filmProduction: 'Filmproduktion',
      filmDesc: 'Full-Service Filmproduktion von Konzept bis Auslieferung',
      postProduction: 'Postproduktion',
      postDesc: 'Professioneller Schnitt, Farbkorrektur und visuelle Effekte',
      dronePhotography: 'Drohnenflüge',
      droneDesc: 'Atemberaubende Luftaufnahmen und Fotografie',
      webDesign: 'Webdesign',
      webDesc: 'Moderne, responsive Websites mit beeindruckender Optik',
      exploreService: 'Mehr erfahren'
    },
    en: {
      title: 'Services',
      filmProduction: 'Film Production',
      filmDesc: 'Full-service film production from concept to final delivery',
      postProduction: 'Post Production',
      postDesc: 'Professional editing, color grading, and visual effects',
      dronePhotography: 'Drone Photography',
      droneDesc: 'Breathtaking aerial cinematography and photography',
      webDesign: 'Web Design',
      webDesc: 'Modern, responsive websites with stunning visuals',
      exploreService: 'Learn More'
    }
  },
  drone: {
    de: {
      title: 'Drohnenflüge',
      camera: 'Drohne',
      storage: 'Projekt',
      services: 'Meine Drohnen-Services'
    },
    en: {
      title: 'Drone Photography',
      camera: 'Drone',
      storage: 'Project',
      services: 'My Drone Services'
    }
  }
};