import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import SectionTitle from '../UI/SectionTitle';
import TestimonialsList from './Testimonials/TestimonialsList';

const TestimonialsSection = () => {
  const { language } = useLanguage();

  return (
    <section className="relative bg-black py-16 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 via-black/80 to-black" />
      <div className="absolute top-0 left-0 w-1/2 h-1/2 bg-purple-500/20 blur-[100px] opacity-30" />
      <div className="absolute bottom-0 right-0 w-1/2 h-1/2 bg-blue-500/20 blur-[100px] opacity-30" />

      <div className="relative container mx-auto px-6">
        <SectionTitle>
          {language === 'de' ? 'Kunden' : 'Clients'}
        </SectionTitle>
        <div className="mb-8">
          <TestimonialsList />
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;