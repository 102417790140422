import { useState, useRef, useEffect } from 'react';

interface UseYouTubePlayerProps {
  videoId: string;
}

export const useYouTubePlayer = ({ videoId }: UseYouTubePlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerInstanceRef = useRef<any>(null);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      return new Promise((resolve) => {
        if ((window as any).YT && (window as any).YT.Player) {
          resolve((window as any).YT);
        } else {
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
          (window as any).onYouTubeIframeAPIReady = () => resolve((window as any).YT);
        }
      });
    };

    const initializePlayer = async () => {
      try {
        const YT = await loadYouTubeAPI();
        if (iframeRef.current) {
          playerInstanceRef.current = new (YT as any).Player(iframeRef.current, {
            videoId,
            playerVars: {
              autoplay: 0,
              controls: 0,
              modestbranding: 1,
              rel: 0,
              playsinline: 1
            },
            events: {
              onStateChange: (event: any) => {
                setIsPlaying(event.data === (YT as any).PlayerState.PLAYING);
              },
              onReady: (event: any) => {
                setDuration(event.target.getDuration());
              }
            }
          });
        }
      } catch (error) {
        console.error('Error initializing YouTube player:', error);
      }
    };

    initializePlayer();

    const progressInterval = setInterval(() => {
      if (playerInstanceRef.current?.getCurrentTime) {
        setProgress(playerInstanceRef.current.getCurrentTime());
      }
    }, 1000);

    return () => {
      clearInterval(progressInterval);
      if (playerInstanceRef.current) {
        playerInstanceRef.current.destroy();
      }
    };
  }, [videoId]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const togglePlay = () => {
    if (playerInstanceRef.current) {
      if (isPlaying) {
        playerInstanceRef.current.pauseVideo();
      } else {
        playerInstanceRef.current.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (playerInstanceRef.current) {
      if (isMuted) {
        playerInstanceRef.current.unMute();
      } else {
        playerInstanceRef.current.mute();
      }
      setIsMuted(!isMuted);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      playerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const getEmbedUrl = () => {
    return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&modestbranding=1&playsinline=1&rel=0&controls=0&origin=${window.location.origin}`;
  };

  return {
    isPlaying,
    isMuted,
    isFullscreen,
    progress,
    duration,
    playerRef,
    iframeRef,
    togglePlay,
    toggleMute,
    toggleFullscreen,
    getEmbedUrl
  };
};