import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  link: string;
  bgImage: string;
  alt: string;
  exploreText: string;
  index: number;
}

const ServiceCard = ({ icon, title, description, link, bgImage, alt, exploreText, index }: ServiceCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
    >
      <Link 
        to={link} 
        className="group relative h-[400px] rounded-lg overflow-hidden block transform transition-transform duration-500 hover:scale-[1.02]"
      >
        <div className="absolute inset-0">
          <img 
            src={bgImage}
            alt={alt}
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
          <div className="absolute inset-0 bg-purple-900/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        </div>
        
        <div className="absolute inset-0 p-8 flex flex-col justify-end">
          <div className="text-white mb-4 transform translate-y-0 group-hover:-translate-y-4 transition-transform duration-300">
            {icon}
          </div>
          <h3 className="text-3xl text-white mb-4 transform translate-y-0 group-hover:-translate-y-4 transition-transform duration-300">
            {title}
          </h3>
          <p className="text-white/80 mb-6 transform translate-y-0 group-hover:-translate-y-4 transition-transform duration-300">
            {description}
          </p>
          <span 
            className="inline-flex items-center text-purple-400 hover:text-purple-300 transition-colors duration-300 transform translate-y-8 group-hover:translate-y-0 opacity-0 group-hover:opacity-100"
          >
            <span className="mr-2">{exploreText}</span>
            <ArrowRight size={20} />
          </span>
        </div>

        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-br from-purple-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 blur-xl" />
        <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-to-tl from-blue-500/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 blur-xl" />
      </Link>
    </motion.div>
  );
};

export default ServiceCard;