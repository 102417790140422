import React from 'react';
import { motion } from 'framer-motion';
import { Edit, Palette, Film, Music } from 'lucide-react';
import ServiceHero from '../common/ServiceHero';
import ServiceFeature from '../common/ServiceFeature';
import { useLanguage } from '../../../context/LanguageContext';

const PostProductionPage = () => {
  const { language } = useLanguage();

  const features = [
    {
      icon: <Edit className="w-8 h-8" />,
      title: language === 'de' ? 'Professioneller Schnitt' : 'Professional Editing',
      description: language === 'de'
        ? 'Präzise Montage für perfekten Rhythmus und Dramaturgie'
        : 'Precise editing for perfect rhythm and dramaturgy'
    },
    {
      icon: <Palette className="w-8 h-8" />,
      title: language === 'de' ? 'Farbkorrektur' : 'Color Grading',
      description: language === 'de'
        ? 'Hochwertige Farbbearbeitung für cineastischen Look'
        : 'High-end color grading for cinematic look'
    },
    {
      icon: <Music className="w-8 h-8" />,
      title: language === 'de' ? 'Sound Design' : 'Sound Design',
      description: language === 'de'
        ? 'Professionelle Tonbearbeitung und Soundeffekte'
        : 'Professional audio editing and sound effects'
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      <ServiceHero
        title={language === 'de' ? 'Postproduktion' : 'Post Production'}
        subtitle={language === 'de'
          ? 'Professioneller Schnitt, Farbkorrektur und visuelle Effekte'
          : 'Professional editing, color grading, and visual effects'
        }
        backgroundImage="https://images.unsplash.com/photo-1535016120720-40c646be5580?auto=format&fit=crop&q=80&w=2000"
        icon={<Film className="w-12 h-12" />}
      />

      <motion.div 
        className="container mx-auto px-6 py-24 grid grid-cols-1 md:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {features.map((feature, index) => (
          <ServiceFeature
            key={index}
            {...feature}
            index={index}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default PostProductionPage;