import React from 'react';
import { motion } from 'framer-motion';
import { Film, Camera, Users, Star } from 'lucide-react';
import ServiceHero from '../common/ServiceHero';
import ServiceFeature from '../common/ServiceFeature';
import { useLanguage } from '../../../context/LanguageContext';

const FilmProductionPage = () => {
  const { language } = useLanguage();

  const features = [
    {
      icon: <Camera className="w-8 h-8" />,
      title: language === 'de' ? 'Professionelle Ausrüstung' : 'Professional Equipment',
      description: language === 'de' 
        ? 'Modernste Kameras und Aufnahmetechnik für beste Bildqualität'
        : 'State-of-the-art cameras and recording technology for best image quality'
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: language === 'de' ? 'Erfahrenes Team' : 'Experienced Team',
      description: language === 'de'
        ? 'Kreative Experten für jede Position der Produktion'
        : 'Creative experts for every production position'
    },
    {
      icon: <Star className="w-8 h-8" />,
      title: language === 'de' ? 'Full-Service' : 'Full Service',
      description: language === 'de'
        ? 'Von der Konzeption bis zur finalen Auslieferung'
        : 'From conception to final delivery'
    }
  ];

  return (
    <div className="min-h-screen bg-black">
      <ServiceHero
        title={language === 'de' ? 'Filmproduktion' : 'Film Production'}
        subtitle={language === 'de' 
          ? 'Full-Service Filmproduktion von Konzept bis Auslieferung'
          : 'Full-service film production from concept to delivery'
        }
        backgroundImage="https://images.unsplash.com/photo-1485846234645-a62644f84728?auto=format&fit=crop&q=80&w=2000"
        icon={<Film className="w-12 h-12" />}
      />

      <motion.div 
        className="container mx-auto px-6 py-24 grid grid-cols-1 md:grid-cols-3 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {features.map((feature, index) => (
          <ServiceFeature
            key={index}
            {...feature}
            index={index}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default FilmProductionPage;