import React from 'react';
import { useLanguage } from '../../context/LanguageContext';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex space-x-4">
      <button
        onClick={() => setLanguage('en')}
        className={`text-sm uppercase ${
          language === 'en' 
            ? 'text-purple-400' 
            : 'text-white/60 hover:text-white'
        }`}
      >
        EN
      </button>
      <button
        onClick={() => setLanguage('de')}
        className={`text-sm uppercase ${
          language === 'de' 
            ? 'text-purple-400' 
            : 'text-white/60 hover:text-white'
        }`}
      >
        DE
      </button>
    </div>
  );
}

export default LanguageSwitcher;