import { useLanguage } from '../../../context/LanguageContext';

export const useBiographyContent = () => {
  const { language } = useLanguage();

  return language === 'de' ? {
    title: 'Filmemacher aus Leidenschaft – Meine Geschichte',
    paragraphs: [
      'Seit drei Jahren lebe ich meinen Traum: Ich mache Filme, erzähle Geschichten und schaffe Momente, die im Gedächtnis bleiben. Dabei spielt es für mich keine Rolle, ob es ein emotionaler Dokumentarfilm, ein packender Imagefilm oder Content für YouTube, TikTok und Instagram ist – ich liebe es einfach, Menschen zu berühren und Gänsehaut-Momente zu kreieren.',
      'Was mich antreibt, ist die Leidenschaft, etwas Perfektes zu schaffen. Jedes Detail zählt: der perfekte Schnitt, die richtige Beleuchtung, der Moment, in dem die Musik die Bilder trägt. Ich bin ein Perfektionist, aber nicht, weil ich muss, sondern weil ich es liebe.',
      'Die Verbindung von Technik und Kreativität ist für mich magisch. Egal, ob ich an einem großen Projekt arbeite oder kurzen Content für Social Media schaffe – ich stecke in alles mein Herzblut. Filme zu machen ist nicht nur mein Job, es ist meine Art, die Welt zu sehen und zu teilen.'
    ]
  } : {
    title: 'Filmmaker by Passion – My Story',
    paragraphs: [
      'For three years, I\'ve been living my dream: making films, telling stories, and creating moments that last in memory. Whether it\'s an emotional documentary, a compelling image film, or content for YouTube, TikTok, and Instagram – I simply love touching people and creating goosebump moments.',
      'What drives me is the passion to create something perfect. Every detail matters: the perfect cut, the right lighting, the moment when music carries the images. I\'m a perfectionist, not because I have to be, but because I love it.',
      'The connection between technology and creativity is magical to me. Whether I\'m working on a big project or creating short content for social media – I put my heart and soul into everything. Making films isn\'t just my job, it\'s my way of seeing and sharing the world.'
    ]
  };
};