import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import SectionTitle from '../UI/SectionTitle';
import Preview from './PostProduction/Preview';
import Features from './PostProduction/Features';
import Skills from './PostProduction/Skills';

const PostProductionSection = () => {
  const { language } = useLanguage();

  return (
    <section className="relative min-h-screen bg-black py-24 overflow-hidden">
      {/* Background Image with Gradient */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-fixed opacity-40"
        style={{
          backgroundImage: `url('https://i.ibb.co/TwHQr7k/hollywood-lg.jpg')`
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-b from-purple-900/40 via-black/80 to-black" />
      
      {/* Atmospheric Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-1/2 h-1/2 bg-purple-500/20 blur-[100px] opacity-30" />
        <div className="absolute bottom-0 right-0 w-1/2 h-1/2 bg-blue-500/20 blur-[100px] opacity-30" />
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-6">
        <SectionTitle>
          {language === 'de' ? 'Postproduktion' : 'Post Production'}
        </SectionTitle>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <Preview />
          <Features />
        </div>

        <Skills />
      </div>

      {/* Additional Decorative Elements */}
      <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-purple-500/10 rounded-full filter blur-3xl animate-pulse" />
      <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-blue-500/10 rounded-full filter blur-3xl animate-pulse delay-1000" />
    </section>
  );
};

export default PostProductionSection;