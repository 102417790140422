import React from 'react';

interface DronePlayerProgressProps {
  progress: number;
  duration: number;
}

const DronePlayerProgress = ({ progress, duration }: DronePlayerProgressProps) => {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="relative group">
      <div className="h-1 bg-white/20 rounded-full overflow-hidden">
        <div 
          className="h-full bg-purple-500 transition-all duration-300"
          style={{ width: `${(progress / duration) * 100}%` }}
        />
      </div>
      
      <div className="absolute -top-6 left-0 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none hidden sm:block">
        <div className="bg-black/90 text-white text-xs px-2 py-1 rounded">
          {formatTime(progress)}
        </div>
      </div>
      
      <div className="absolute -top-6 right-0 transform translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none hidden sm:block">
        <div className="bg-black/90 text-white text-xs px-2 py-1 rounded">
          {formatTime(duration)}
        </div>
      </div>

      <div className="flex justify-between text-xs text-white/60 mt-1 sm:hidden">
        <span>{formatTime(progress)}</span>
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  );
};

export default DronePlayerProgress;