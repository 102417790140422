import React from 'react';
import DronePlayer from '../Video/DronePlayer/DronePlayer';
import { useLanguage } from '../../context/LanguageContext';

const DroneFrame = () => {
  const { language } = useLanguage();
  const videoId = 'QNGbyK88S1E';

  return (
    <div className="relative max-w-6xl mx-auto">
      <div className="relative aspect-video rounded-lg overflow-hidden border-8 border-black/50 shadow-2xl transform hover:scale-[1.02] transition-transform duration-500">
        <DronePlayer 
          videoId={videoId}
          title={language === 'de' ? 'Drohnenaufnahmen Showreel' : 'Drone Footage Showreel'}
          thumbnail="https://i.ibb.co/YhhsLMq/drone-thumbnail.jpg"
        />
      </div>

      <div className="absolute -top-4 -left-4 w-24 h-24 border-l-4 border-t-4 border-purple-500/50" />
      <div className="absolute -bottom-4 -right-4 w-24 h-24 border-r-4 border-b-4 border-purple-500/50" />
      
      <div className="absolute -inset-4 bg-purple-500/20 blur-3xl opacity-30" />
      <div className="absolute -inset-2 bg-gradient-to-r from-purple-500/20 to-transparent blur-2xl opacity-20" />
    </div>
  );
};

export default DroneFrame;