import React from 'react';
import { motion } from 'framer-motion';
import BiographyTitle from './BiographyTitle';
import BiographyParagraph from './BiographyParagraph';
import { useBiographyContent } from './BiographyContent';

const BiographySection = () => {
  const content = useBiographyContent();

  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="space-y-6"
    >
      <BiographyTitle title={content.title} />
      {content.paragraphs.map((paragraph, index) => (
        <BiographyParagraph 
          key={index} 
          text={paragraph} 
          index={index} 
        />
      ))}
    </motion.div>
  );
};

export default BiographySection;