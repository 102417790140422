import React from 'react';
import { useWorkProjects } from '../../hooks/useWorkProjects';
import FeaturedProject from './FeaturedProject';

const FeaturedProjects = () => {
  const projects = useWorkProjects();

  return (
    <div className="space-y-16">
      {projects.map((project) => (
        <FeaturedProject key={project.id} project={project} />
      ))}
    </div>
  );
};

export default FeaturedProjects;