import React from 'react';
import { Play } from 'lucide-react';
import { useLanguage } from '../../../../context/LanguageContext';

interface ThumbnailViewProps {
  thumbnail: string;
  title: string;
  onPlay: () => void;
}

const ThumbnailView: React.FC<ThumbnailViewProps> = ({ thumbnail, title, onPlay }) => {
  const { language } = useLanguage();

  return (
    <div className="absolute inset-0">
      <img 
        src={thumbnail} 
        alt={title}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
        <button
          onClick={onPlay}
          className="bg-purple-600/80 p-6 sm:p-8 rounded-full text-white transform hover:scale-110 transition-transform duration-300 hover:bg-purple-600"
          aria-label={language === 'de' ? 'Video abspielen' : 'Play video'}
        >
          <Play size={24} className="sm:w-12 sm:h-12 ml-1" />
        </button>
      </div>
    </div>
  );
};

export default ThumbnailView;