import React from 'react';
import { motion } from 'framer-motion';

interface FormTextAreaProps {
  id: string;
  name: string;
  label: string;
  required?: boolean;
}

const FormTextArea = ({ 
  id, 
  name, 
  label, 
  required = true 
}: FormTextAreaProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: 0.4 }}
      className="relative"
    >
      <textarea
        id={id}
        name={name}
        rows={5}
        className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white focus:border-purple-500 transition-colors peer"
        required={required}
        placeholder=" "
      />
      <label
        htmlFor={id}
        className="absolute left-4 -top-2 text-xs bg-black px-2 text-purple-400 transition-all duration-200"
      >
        {label}
      </label>
    </motion.div>
  );
};

export default FormTextArea;