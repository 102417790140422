import React from 'react';
import { motion } from 'framer-motion';

interface BiographyParagraphProps {
  text: string;
  index: number;
}

const BiographyParagraph = ({ text, index }: BiographyParagraphProps) => {
  return (
    <motion.p
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
      className="text-white/80 text-lg leading-relaxed"
    >
      {text}
    </motion.p>
  );
};

export default BiographyParagraph;