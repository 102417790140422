import { useLanguage } from '../context/LanguageContext';

interface Project {
  id: number;
  title: string;
  description: string;
  thumbnail: string;
  category: string;
  videoId: string;
  specs: {
    icon: string;
    label: string;
    value: string;
  }[];
}

export const useWorkProjects = (): Project[] => {
  const { language } = useLanguage();

  return [
    {
      id: 1,
      title: language === 'de' ? "Schüler in Auschwitz" : "Students in Auschwitz",
      description: language === 'de' 
        ? "Dokumentation der Heinz-Brandt-Schule" 
        : "Documentation of Heinz-Brandt-School",
      thumbnail: "https://i.ibb.co/Vp5qwCK/auschwitz.jpg",
      category: language === 'de' ? "Dokumentation" : "Documentary",
      videoId: "1035309874",
      specs: [
        {
          icon: "camera",
          label: language === 'de' ? "Equipment" : "Equipment",
          value: "Sony PXW-Z200 4K HDR"
        },
        {
          icon: "hardDrive",
          label: language === 'de' ? "Materialgröße" : "Project Size",
          value: "500 GB"
        },
        {
          icon: "calendar",
          label: language === 'de' ? "Aufnahmedatum" : "Recording Date",
          value: "2022"
        },
        {
          icon: "user",
          label: language === 'de' ? "Credits" : "Credits",
          value: language === 'de' 
            ? "Kamera, Regie, Schnitt: Lars Janetzke"
            : "Camera, Direction, Editing: Lars Janetzke"
        }
      ]
    },
    {
      id: 2,
      title: language === 'de' ? "Jakobsweg 2024" : "Camino de Santiago 2024",
      description: language === 'de' 
        ? "Dokumentation der Heinz-Brandt-Schule" 
        : "Documentation of Heinz-Brandt-School",
      thumbnail: "https://i.ibb.co/W3cPxWT/jakobsweg.jpg",
      category: language === 'de' ? "Adventure" : "Adventure",
      videoId: "MLGXlZ7eywc",
      specs: [
        {
          icon: "camera",
          label: language === 'de' ? "Equipment" : "Equipment",
          value: "2x GoPro Hero 11 • DJI Mini 4K"
        },
        {
          icon: "hardDrive",
          label: language === 'de' ? "Materialgröße" : "Project Size",
          value: "1.5 TB"
        },
        {
          icon: "calendar",
          label: language === 'de' ? "Aufnahmedatum" : "Recording Date",
          value: "2024"
        },
        {
          icon: "user",
          label: language === 'de' ? "Credits" : "Credits",
          value: language === 'de' 
            ? "Kamera, Regie, Schnitt: Lars Janetzke"
            : "Camera, Direction, Editing: Lars Janetzke"
        }
      ]
    }
  ];
};