import React from 'react';

const VideoBackground = () => {
  return (
    <div className="fixed inset-0 w-full h-full -z-10">
      <div className="absolute inset-0 bg-black">
        <iframe 
          src="https://player.vimeo.com/video/1035294933?background=1&autoplay=1&loop=1&byline=0&title=0&muted=1"
          className="absolute w-full h-full"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) scale(1.5)', // Scale up to ensure coverage
            width: '177.77777778vh', // 16:9 aspect ratio
            height: '56.25vw',
            minWidth: '100%',
            minHeight: '100%'
          }}
        />
      </div>
      <div className="absolute inset-0 bg-black/40" />
    </div>
  );
};

export default VideoBackground;